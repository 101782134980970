@import url('https://fonts.googleapis.com/css?family=Inter');
$homePageBlueThem: #05073c;

@mixin btn12pxPadding($color, $backgroundColor, $borderColor, $hoverBackgroundColor, $hoverColor, $width ) {
    width: $width;
    font-family: 'Inter';
    color: $color;
    background-color: $backgroundColor;
    border: 1px solid $borderColor;
    outline: none;
    border-radius: 20px;
    padding: 12px;
    cursor: pointer;
    transition: all 0.7s;

    &:hover {
        background-color: $hoverBackgroundColor;
        color: $hoverColor;
    }
}

@mixin btnWithHeigthCircular($backgroundColor, $width, $heigth, $borderColor) {
    width: $width;
    height: $heigth;
    font-family: 'Inter';
    background-color: $backgroundColor;
    border: 1px solid $borderColor;
    outline: none;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.7s;

    &:hover {
        background-color: darken($color: $backgroundColor, $amount: 10);
    }
}



.GeneralLayout {
    font-family: 'Inter';
    position: relative;
    height: fit-content;
    overflow: 'scroll';

    .header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        display: flex;
        align-items: center;
        background-color: $homePageBlueThem;
        flex-wrap: wrap;
        padding-bottom: 10px;
        transition: all 0.5s;

        %commonheaderelem {
            flex-grow: 1;
        }

        .header-left-side {
            display: flex;
            flex-direction: row;
            align-items: center;
            @extend %commonheaderelem;
            column-gap: 20px;
            justify-content: center;

            h1 {
                color: white;
            }

            nav {
                display: flex;
                flex-wrap: wrap;

                .link {
                    text-decoration: none;
                    font-size: 14px;
                    @include btn12pxPadding(white, transparent, transparent, rgb(239, 239, 239, 0.2), white, fit-content)
                }
            }
        }

        .header-right-side {
            display: flex;
            flex-direction: row;
            align-items: center;
            @extend %commonheaderelem;
            justify-content: center;

            .signup-btn {
                text-decoration: none;
                @include btn12pxPadding(white, transparent, transparent, transparent, rgb(6, 123, 186), fit-content);
            }

            .login-btn {
                text-decoration: none;
                @include btn12pxPadding(white, transparent, white, white, black, fit-content);
            }
        }
    }

    .footer {

        background-color: $homePageBlueThem;
        height: fit-content;
        color: white;
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        $commonSecPadding: 10px 16px 30px 50px;
        $commonBorderBottom: 0.7px solid rgb(153, 153, 153, 0.5);

        .footer-upperSec {
            border-bottom: $commonBorderBottom;
            padding: $commonSecPadding;
            display: flex;
            flex-wrap: wrap;
            row-gap: 20px;
            align-items: center;
            padding-top: 50px;
            background-image: url('./svg/footerUpperSecBackground.svg');
            background-repeat: no-repeat;
            background-size: cover;

            .footer-upperSec-contacts {
                flex-grow: 2;
                display: flex;
                align-items: center;

                img {
                    width: 50px;
                }
            }

            .footer-upperSec-socialMedia {
                flex-grow: 1;
                display: flex;
                align-items: center;
                column-gap: 20px;
                justify-content: center;

                img {
                    width: 20px;
                }
            }
        }

        .footer-middleSec {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            border-bottom: $commonBorderBottom;
            padding: $commonSecPadding;

            .contact {
                flex-basis: 300px;
            }

            nav {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                row-gap: 10px;

                .link {
                    text-decoration: none;
                    color: white;
                    transition: all 0.5s;
                    font-size: 14px;

                    &:hover {
                        color: rgb(6, 123, 186);
                    }
                }
            }
        }

        .footer-bottomSec {
            padding: $commonSecPadding;
            display: flex;
            flex-wrap: wrap;
            row-gap: 20px;
            align-items: center;

            .footer-bottomSec-leftSec {
                flex-grow: 2;
                justify-content: center;
            }

            .footer-bottomSec-rightSec {
                display: flex;
                column-gap: 10px;
                flex-grow: 1;
                justify-content: center;
            }
        }

    }


}

.QuickSearch {
    background-image: url('./img/kaunas1.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    padding: 200px 0px 0px 0px;

    .QuickSearch-engine {
        width: 70%;
        background-color: white;
        border-radius: 40px;
        width: fit-content;
        padding: 8px 16px 8px 16px;
        display: flex;
        align-items: center;
        column-gap: 50px;
        flex-wrap: wrap;
        row-gap: 10px;

        button {
            background-color: #4a43c4;
            padding: 16px 20px 16px 20px;
            flex-grow: 1;
            border-radius: 45px;
            outline: none;
            border: none;
            cursor: pointer;
            transition: all 0.8s;

            &:hover {
                background-color: darken($color: #4a43c4, $amount: 10);
            }
        }

        .searchOption {
            display: flex;
            column-gap: 8px;
            cursor: pointer;
            position: relative;
            flex-grow: 1;

            img {

                padding: 10px;
                border: 1px solid rgb(180, 180, 180);
                border-radius: 50%;
            }

            img {
                width: 20px;
            }

            .optionSelection {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .searchName {
                    font-weight: 600;
                }

                .choosenOption {
                    color: #737373;
                    font-size: 12px;
                }

            }

            .options {
                position: absolute;
                background-color: white;
                border-radius: 10px;
                box-shadow: 1px 1px 10px gray;
                top: 60px;
                height: 250px;
                width: 335px;
                overflow-y: auto;

                .option {
                    padding: 16px 15px 16px 15px;
                    display: grid;
                    grid-template-columns: auto auto;
                    align-items: center;

                    .type {
                        justify-self: flex-end;
                        color: gray;
                        font-size: 11px;
                    }

                    transition: all 0.4s;
                }

                .option:hover {
                    background-color: rgb(222, 222, 222);
                }

                /* Custom scrollbar styles */
                &::-webkit-scrollbar-track {
                    background-color: transparent;
                    margin-top: 8px;
                    margin-bottom: 8px;
                }

                &::-webkit-scrollbar {
                    width: 2px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #888888;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background-color: #555555;
                }
            }
        }
    }

    .QuickSearch-slogan {
        width: 70%;
        text-align: center;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-shadow: 1px 1px 10px rgb(7, 7, 7, 0.5);

        h1 {
            font-size: 70px;
        }

        span {
            display: block;
            width: 50%;
        }
    }
}

.WhyChooseTravelSwift {
    width: 80%;
    margin: 100px auto 100px auto;
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    h2 {
        text-align: center;

    }

    .facts {
        display: flex;
        flex-direction: row;
        column-gap: 16px;
        align-self: center;
        flex-wrap: wrap;
        row-gap: 30px;

        .fact {
            flex-basis: 240px;
            flex-grow: 1;
            cursor: pointer;
            padding: 36px 16px 36px 16px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            h3 {
                font-weight: 600;
            }

            transition: all 0.7s;

            &:hover {
                box-shadow: 1px 1px 10px gray;
            }
        }
    }
}

.TrendingDestination {
    width: 80%;
    margin: 100px auto 0px auto;

    .TrendingDestination-upperSec {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        h2 {
            font-weight: bold;
            flex-grow: 1;
        }

        .link {
            flex-grow: 1;
            justify-content: flex-end;
            text-decoration: none;
            color: black;
            display: flex;
            align-items: center;

            img {

                width: 50px;
            }
        }
    }


    .destinations {
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 20px;

        .destination {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 20px;
            column-gap: 20px;
            flex-grow: 1;
            border: 1px solid rgb(202, 202, 202);
            border-radius: 10px;
            row-gap: 10px;
            cursor: pointer;
            transition: all 0.5s;

            .destinations-destination-title {
                display: flex;
                flex-direction: column;

                .title {
                    font-weight: bold;
                }
            }

            &:hover {
                box-shadow: 1px 1px 10px gray;

                .destinations-destination-title {

                    .numTours {
                        color: #4a43c4;
                    }
                }
            }
        }
    }
}

.SignUp {
    font-family: 'Inter';
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px auto 100px auto;
    position: relative;

    .loginText {
        font-size: 12px;
        margin: 20px 0px 20px 0px;

        .link {
            text-decoration: none;
            color: #d93025;

            &:hover {
                color: #1967d2;
            }
        }

    }

    form {
        width: 35%;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        border: 1px solid #E7E6E6;
        border-radius: 12px;
        padding: 20px;
        align-items: center;

        label {
            width: 95%;
            position: relative;

            input {
                width: inherit;
                border-radius: 12px;
                height: 20px;
                padding: 15px;
                outline: none;
                border: 1px solid #E7E6E6;
            }

            .animatedPlaceHolder {
                position: absolute;
                top: 16px;
                left: 17px;
            }

            @keyframes placeholderMoveUp {
                100% {
                    top: -10px;
                    left: 15px;
                    background-color: white;
                    padding: 0px 2px 0px 2px;
                }
            }

            @keyframes placeholderMoveDown {
                0% {
                    top: -10px;
                    left: 15px;
                    background-color: white;
                    padding: 0px 2px 0px 2px;
                }

                100% {
                    top: 16px;
                    left: 15px;
                    padding: 0px 2px 0px 2px;
                }
            }

        }

        .registerAsTitle {
            display: flex;
            align-items: center;
            column-gap: 8px;
            width: 100%;

            .line {
                flex-grow: 5;
                height: 0px;
                border: 1px solid #E7E6E6;
            }

            span {
                flex-grow: 1;
                text-align: center;
            }
        }

        .accountType {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;
            column-gap: 20px;
            row-gap: 16px;

            .type {
                text-align: center;
                flex-grow: 1;
                border-radius: 12px;
                padding: 15px;
                cursor: pointer;
                transition: all 0.5s;
            }

            .guide {
                border: 1px solid #1967d2;
                color: #1967d2;

                &:hover {
                    background-color: #1967d2;
                    color: white;
                }
            }

            .tourist {
                border: 1px solid #d93025;
                color: #d93025;

                &:hover {
                    background-color: #d93025;
                    color: white;
                }
            }
        }

        .register {
            display: flex;
            align-items: center;
            justify-content: center;
            @include btn12pxPadding(white, #eb662b, transparent, #b93e09, white, 100%);
            height: 55px;
            font-weight: 600px;

            img {
                width: 50px;
            }
        }
    }
}

.Login {
    font-family: 'Inter';
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px auto 100px auto;
    position: relative;

    .signupText {
        font-size: 12px;
        margin: 20px 0px 20px 0px;

        .link {
            text-decoration: none;
            color: #d93025;

            &:hover {
                color: #1967d2;
            }
        }

    }

    form {
        width: 35%;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        border: 1px solid #E7E6E6;
        border-radius: 12px;
        padding: 20px;
        align-items: center;

        label {
            width: 95%;
            position: relative;

            input {
                width: inherit;
                border-radius: 12px;
                height: 20px;
                padding: 15px;
                outline: none;
                border: 1px solid #E7E6E6;
            }

            .animatedPlaceHolder {
                position: absolute;
                top: 16px;
                left: 17px;
            }

            @keyframes placeholderMoveUp {
                100% {
                    top: -10px;
                    left: 15px;
                    background-color: white;
                    padding: 0px 2px 0px 2px;
                }
            }

            @keyframes placeholderMoveDown {
                0% {
                    top: -10px;
                    left: 15px;
                    background-color: white;
                    padding: 0px 2px 0px 2px;
                }

                100% {
                    top: 16px;
                    left: 15px;
                    padding: 0px 2px 0px 2px;
                }
            }

            .error {
                padding: 10px 0px 0px 10px;
                color: red;
                font-size: 14px;
            }

        }

        .loginAsTitle {
            display: flex;
            align-items: center;
            column-gap: 8px;
            width: 100%;

            .line {
                flex-grow: 5;
                height: 0px;
                border: 1px solid #E7E6E6;
            }

            span {
                flex-grow: 1;
                text-align: center;
            }
        }

        .accountType {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;
            column-gap: 20px;
            row-gap: 16px;

            .type {
                text-align: center;
                flex-grow: 1;
                border-radius: 12px;
                padding: 15px;
                cursor: pointer;
                transition: all 0.5s;
            }

            .guide {
                border: 1px solid #1967d2;
                color: #1967d2;

                &:hover {
                    background-color: #1967d2;
                    color: white;
                }
            }

            .tourist {
                border: 1px solid #d93025;
                color: #d93025;

                &:hover {
                    background-color: #d93025;
                    color: white;
                }
            }
        }

        .login {
            display: flex;
            align-items: center;
            justify-content: center;
            @include btn12pxPadding(white, #eb662b, transparent, #b93e09, white, 100%);
            height: 55px;
            font-weight: 600px;

            img {
                width: 50px;
            }
        }
    }
}

.SuccessRegistered {

    font-family: 'Inter';
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 360px;
    margin: 150px auto 100px auto;
    box-shadow: 1px 1px 10px gray;
    padding: 40px 30px 40px 30px;
    border-radius: 10px;
    row-gap: 20px;

    .text {
        text-align: center;
    }

    .link {
        text-decoration: none;
        padding: 10px 20px 10px 20px;
        background-color: #eb662b;
        color: white;
        border-radius: 10px;
        transition: all 0.5s;
    }

    .link:hover {
        background-color: darken($color: #eb662b, $amount: 10);
        box-shadow: 1px 1px 10px gray;
    }
}

.GuidePostIcome {

    background-image: url('./img/passiveIncmeBackground.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: fit-content;

    .GuidePostIcome-leftSide {
        width: 50%;
        display: flex;
        justify-content: center;

        .GuidePostIcome-leftSide-alignement {
            width: 50%;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            h1 {
                max-width: 400px;
                .keyWords{
                    color: lighten($color: $homePageBlueThem, $amount: 50);
                }
            }
            .link{
                text-decoration: none;
            }
            button {
                display: flex;
                align-items: center;
                @include btn12pxPadding(white, lighten($color: $homePageBlueThem, $amount: 50), transparent, $homePageBlueThem , white, fit-content);
                padding-left: 20px;
            }
        }
    }

    .GuidePostIcome-rightSide {
        max-width: 50%;
        position: relative;

        .sideWhiteWave {
            position: absolute;
            top: 0px;
        }

        .bottomWhiteWave {
            position: absolute;
            top: 0px;
            width: 98vw;
        }
    }
}

@media (max-width: 900px) {
    .GeneralLayout {
        .header {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1000;

            .header-left-side {
                position: relative;

                nav {
                    position: absolute;
                    display: none;
                    flex-wrap: wrap;

                    .link {
                        text-decoration: none;
                        font-size: 14px;
                        @include btn12pxPadding(white, transparent, transparent, rgb(239, 239, 239, 0.2), white, fit-content)
                    }
                }
            }
        }
    }

    .QuickSearch {

        padding: 100px 0px 0px 0px;
        row-gap: 50px;

        .QuickSearch-engine {
            padding: 20px 0px 0px 0px;
            border-radius: 10px;

            width: 80%;

            .searchOption {
                display: flex;
                column-gap: 8px;
                cursor: pointer;
                position: relative;
                flex-grow: 1;
                flex-basis: 180px;


                img {
                    padding: 10px;
                    border: 1px solid rgb(180, 180, 180);
                    border-radius: 50%;
                    width: 20px;
                    margin: 0px 0px 0px 20px;
                }

                .optionSelection {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .searchName {
                        font-weight: 600;
                    }

                    .choosenOption {
                        color: #737373;
                        font-size: 12px;
                    }

                }

                .options {
                    position: absolute;
                    background-color: white;
                    border-radius: 10px;
                    box-shadow: 1px 1px 10px gray;
                    top: 60px;
                    height: 250px;
                    width: 335px;
                    overflow-y: auto;
                    z-index: 1;

                    .option {
                        padding: 16px 15px 16px 15px;
                        display: grid;
                        grid-template-columns: auto auto;
                        align-items: center;

                        .type {
                            justify-self: flex-end;
                            color: gray;
                            font-size: 11px;
                        }

                        transition: all 0.4s;
                    }

                    .option:hover {
                        background-color: rgb(222, 222, 222);
                    }

                    /* Custom scrollbar styles */
                    &::-webkit-scrollbar-track {
                        background-color: transparent;
                        margin-top: 8px;
                        margin-bottom: 8px;
                    }

                    &::-webkit-scrollbar {
                        width: 2px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #888888;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background-color: #555555;
                    }
                }
            }

            button {
                background-color: #4a43c4;
                padding: 16px 20px 16px 20px;
                flex-grow: 1;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: inherit;
                border-bottom-left-radius: inherit;
                outline: none;
                border: none;
                cursor: pointer;
                transition: all 0.8s;
                flex-basis: 170px;

                &:hover {
                    background-color: darken($color: #4a43c4, $amount: 10);
                }
            }

        }

        .QuickSearch-slogan {
            h1 {
                font-size: 50px;
            }

            span {
                display: block;
                width: 70%;
            }
        }
    }

    .SignUp {

        form {
            padding: 20px 8px 10px 8px;
            width: 80%;

            label {
                width: 90%;

                input {
                    width: inherit;
                    border-radius: 12px;
                    height: 20px;
                    padding: 15px;
                    outline: none;
                    border: 1px solid #E7E6E6;
                }
            }
        }
    }

    .Login {

        form {
            padding: 20px 8px 10px 8px;
            width: 80%;

            label {
                width: 90%;

                input {
                    width: inherit;
                    border-radius: 12px;
                    height: 20px;
                    padding: 15px;
                    outline: none;
                    border: 1px solid #E7E6E6;
                }
            }
        }
    }

    .GuidePostIcome {

        .GuidePostIcome-leftSide {
            flex-grow: 1;
            width: fit-content;
        }

        .GuidePostIcome-rightSide {
            flex-grow: 1;
            position: relative;

            .mainImage {
                max-width: 100vw;
            }

            .sideWhiteWave {
                position: absolute;
            }
        }
    }
}