@import url("https://fonts.googleapis.com/css?family=Inter");
.GeneralLayout {
  font-family: "Inter";
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  overflow: "scroll";
}
.GeneralLayout .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  background-color: #05073c;
  flex-wrap: wrap;
  padding-bottom: 10px;
  transition: all 0.5s;
}
.GeneralLayout .header .header-right-side, .GeneralLayout .header .header-left-side {
  flex-grow: 1;
}
.GeneralLayout .header .header-left-side {
  display: flex;
  flex-direction: row;
  align-items: center;
  -moz-column-gap: 20px;
       column-gap: 20px;
  justify-content: center;
}
.GeneralLayout .header .header-left-side h1 {
  color: white;
}
.GeneralLayout .header .header-left-side nav {
  display: flex;
  flex-wrap: wrap;
}
.GeneralLayout .header .header-left-side nav .link {
  text-decoration: none;
  font-size: 14px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: "Inter";
  color: white;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  border-radius: 20px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.7s;
}
.GeneralLayout .header .header-left-side nav .link:hover {
  background-color: rgba(239, 239, 239, 0.2);
  color: white;
}
.GeneralLayout .header .header-right-side {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.GeneralLayout .header .header-right-side .signup-btn {
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
  font-family: "Inter";
  color: white;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  border-radius: 20px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.7s;
}
.GeneralLayout .header .header-right-side .signup-btn:hover {
  background-color: transparent;
  color: rgb(6, 123, 186);
}
.GeneralLayout .header .header-right-side .login-btn {
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
  font-family: "Inter";
  color: white;
  background-color: transparent;
  border: 1px solid white;
  outline: none;
  border-radius: 20px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.7s;
}
.GeneralLayout .header .header-right-side .login-btn:hover {
  background-color: white;
  color: black;
}
.GeneralLayout .footer {
  background-color: #05073c;
  height: -moz-fit-content;
  height: fit-content;
  color: white;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.GeneralLayout .footer .footer-upperSec {
  border-bottom: 0.7px solid rgba(153, 153, 153, 0.5);
  padding: 10px 16px 30px 50px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  align-items: center;
  padding-top: 50px;
  background-image: url("./svg/footerUpperSecBackground.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.GeneralLayout .footer .footer-upperSec .footer-upperSec-contacts {
  flex-grow: 2;
  display: flex;
  align-items: center;
}
.GeneralLayout .footer .footer-upperSec .footer-upperSec-contacts img {
  width: 50px;
}
.GeneralLayout .footer .footer-upperSec .footer-upperSec-socialMedia {
  flex-grow: 1;
  display: flex;
  align-items: center;
  -moz-column-gap: 20px;
       column-gap: 20px;
  justify-content: center;
}
.GeneralLayout .footer .footer-upperSec .footer-upperSec-socialMedia img {
  width: 20px;
}
.GeneralLayout .footer .footer-middleSec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 0.7px solid rgba(153, 153, 153, 0.5);
  padding: 10px 16px 30px 50px;
}
.GeneralLayout .footer .footer-middleSec .contact {
  flex-basis: 300px;
}
.GeneralLayout .footer .footer-middleSec nav {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  row-gap: 10px;
}
.GeneralLayout .footer .footer-middleSec nav .link {
  text-decoration: none;
  color: white;
  transition: all 0.5s;
  font-size: 14px;
}
.GeneralLayout .footer .footer-middleSec nav .link:hover {
  color: rgb(6, 123, 186);
}
.GeneralLayout .footer .footer-bottomSec {
  padding: 10px 16px 30px 50px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  align-items: center;
}
.GeneralLayout .footer .footer-bottomSec .footer-bottomSec-leftSec {
  flex-grow: 2;
  justify-content: center;
}
.GeneralLayout .footer .footer-bottomSec .footer-bottomSec-rightSec {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  flex-grow: 1;
  justify-content: center;
}

.QuickSearch {
  background-image: url("./img/kaunas1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  padding: 200px 0px 0px 0px;
}
.QuickSearch .QuickSearch-engine {
  width: 70%;
  background-color: white;
  border-radius: 40px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px 16px 8px 16px;
  display: flex;
  align-items: center;
  -moz-column-gap: 50px;
       column-gap: 50px;
  flex-wrap: wrap;
  row-gap: 10px;
}
.QuickSearch .QuickSearch-engine button {
  background-color: #4a43c4;
  padding: 16px 20px 16px 20px;
  flex-grow: 1;
  border-radius: 45px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.8s;
}
.QuickSearch .QuickSearch-engine button:hover {
  background-color: #3933a1;
}
.QuickSearch .QuickSearch-engine .searchOption {
  display: flex;
  -moz-column-gap: 8px;
       column-gap: 8px;
  cursor: pointer;
  position: relative;
  flex-grow: 1;
}
.QuickSearch .QuickSearch-engine .searchOption img {
  padding: 10px;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 50%;
}
.QuickSearch .QuickSearch-engine .searchOption img {
  width: 20px;
}
.QuickSearch .QuickSearch-engine .searchOption .optionSelection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.QuickSearch .QuickSearch-engine .searchOption .optionSelection .searchName {
  font-weight: 600;
}
.QuickSearch .QuickSearch-engine .searchOption .optionSelection .choosenOption {
  color: #737373;
  font-size: 12px;
}
.QuickSearch .QuickSearch-engine .searchOption .options {
  position: absolute;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 1px 10px gray;
  top: 60px;
  height: 250px;
  width: 335px;
  overflow-y: auto;
  /* Custom scrollbar styles */
}
.QuickSearch .QuickSearch-engine .searchOption .options .option {
  padding: 16px 15px 16px 15px;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  transition: all 0.4s;
}
.QuickSearch .QuickSearch-engine .searchOption .options .option .type {
  justify-self: flex-end;
  color: gray;
  font-size: 11px;
}
.QuickSearch .QuickSearch-engine .searchOption .options .option:hover {
  background-color: rgb(222, 222, 222);
}
.QuickSearch .QuickSearch-engine .searchOption .options::-webkit-scrollbar-track {
  background-color: transparent;
  margin-top: 8px;
  margin-bottom: 8px;
}
.QuickSearch .QuickSearch-engine .searchOption .options::-webkit-scrollbar {
  width: 2px;
}
.QuickSearch .QuickSearch-engine .searchOption .options::-webkit-scrollbar-thumb {
  background-color: #888888;
}
.QuickSearch .QuickSearch-engine .searchOption .options::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}
.QuickSearch .QuickSearch-slogan {
  width: 70%;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-shadow: 1px 1px 10px rgba(7, 7, 7, 0.5);
}
.QuickSearch .QuickSearch-slogan h1 {
  font-size: 70px;
}
.QuickSearch .QuickSearch-slogan span {
  display: block;
  width: 50%;
}

.WhyChooseTravelSwift {
  width: 80%;
  margin: 100px auto 100px auto;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
.WhyChooseTravelSwift h2 {
  text-align: center;
}
.WhyChooseTravelSwift .facts {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 16px;
       column-gap: 16px;
  align-self: center;
  flex-wrap: wrap;
  row-gap: 30px;
}
.WhyChooseTravelSwift .facts .fact {
  flex-basis: 240px;
  flex-grow: 1;
  cursor: pointer;
  padding: 36px 16px 36px 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.7s;
}
.WhyChooseTravelSwift .facts .fact h3 {
  font-weight: 600;
}
.WhyChooseTravelSwift .facts .fact:hover {
  box-shadow: 1px 1px 10px gray;
}

.TrendingDestination {
  width: 80%;
  margin: 100px auto 0px auto;
}
.TrendingDestination .TrendingDestination-upperSec {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.TrendingDestination .TrendingDestination-upperSec h2 {
  font-weight: bold;
  flex-grow: 1;
}
.TrendingDestination .TrendingDestination-upperSec .link {
  flex-grow: 1;
  justify-content: flex-end;
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
}
.TrendingDestination .TrendingDestination-upperSec .link img {
  width: 50px;
}
.TrendingDestination .destinations {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 30px;
       column-gap: 30px;
  row-gap: 20px;
}
.TrendingDestination .destinations .destination {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  -moz-column-gap: 20px;
       column-gap: 20px;
  flex-grow: 1;
  border: 1px solid rgb(202, 202, 202);
  border-radius: 10px;
  row-gap: 10px;
  cursor: pointer;
  transition: all 0.5s;
}
.TrendingDestination .destinations .destination .destinations-destination-title {
  display: flex;
  flex-direction: column;
}
.TrendingDestination .destinations .destination .destinations-destination-title .title {
  font-weight: bold;
}
.TrendingDestination .destinations .destination:hover {
  box-shadow: 1px 1px 10px gray;
}
.TrendingDestination .destinations .destination:hover .destinations-destination-title .numTours {
  color: #4a43c4;
}

.SignUp {
  font-family: "Inter";
  width: 90%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px auto 100px auto;
  position: relative;
}
.SignUp .loginText {
  font-size: 12px;
  margin: 20px 0px 20px 0px;
}
.SignUp .loginText .link {
  text-decoration: none;
  color: #d93025;
}
.SignUp .loginText .link:hover {
  color: #1967d2;
}
.SignUp form {
  width: 35%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border: 1px solid #E7E6E6;
  border-radius: 12px;
  padding: 20px;
  align-items: center;
}
.SignUp form label {
  width: 95%;
  position: relative;
}
.SignUp form label input {
  width: inherit;
  border-radius: 12px;
  height: 20px;
  padding: 15px;
  outline: none;
  border: 1px solid #E7E6E6;
}
.SignUp form label .animatedPlaceHolder {
  position: absolute;
  top: 16px;
  left: 17px;
}
@keyframes placeholderMoveUp {
  100% {
    top: -10px;
    left: 15px;
    background-color: white;
    padding: 0px 2px 0px 2px;
  }
}
@keyframes placeholderMoveDown {
  0% {
    top: -10px;
    left: 15px;
    background-color: white;
    padding: 0px 2px 0px 2px;
  }
  100% {
    top: 16px;
    left: 15px;
    padding: 0px 2px 0px 2px;
  }
}
.SignUp form .registerAsTitle {
  display: flex;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
  width: 100%;
}
.SignUp form .registerAsTitle .line {
  flex-grow: 5;
  height: 0px;
  border: 1px solid #E7E6E6;
}
.SignUp form .registerAsTitle span {
  flex-grow: 1;
  text-align: center;
}
.SignUp form .accountType {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  -moz-column-gap: 20px;
       column-gap: 20px;
  row-gap: 16px;
}
.SignUp form .accountType .type {
  text-align: center;
  flex-grow: 1;
  border-radius: 12px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.5s;
}
.SignUp form .accountType .guide {
  border: 1px solid #1967d2;
  color: #1967d2;
}
.SignUp form .accountType .guide:hover {
  background-color: #1967d2;
  color: white;
}
.SignUp form .accountType .tourist {
  border: 1px solid #d93025;
  color: #d93025;
}
.SignUp form .accountType .tourist:hover {
  background-color: #d93025;
  color: white;
}
.SignUp form .register {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: "Inter";
  color: white;
  background-color: #eb662b;
  border: 1px solid transparent;
  outline: none;
  border-radius: 20px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.7s;
  height: 55px;
  font-weight: 600px;
}
.SignUp form .register:hover {
  background-color: #b93e09;
  color: white;
}
.SignUp form .register img {
  width: 50px;
}

.Login {
  font-family: "Inter";
  width: 90%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px auto 100px auto;
  position: relative;
}
.Login .signupText {
  font-size: 12px;
  margin: 20px 0px 20px 0px;
}
.Login .signupText .link {
  text-decoration: none;
  color: #d93025;
}
.Login .signupText .link:hover {
  color: #1967d2;
}
.Login form {
  width: 35%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border: 1px solid #E7E6E6;
  border-radius: 12px;
  padding: 20px;
  align-items: center;
}
.Login form label {
  width: 95%;
  position: relative;
}
.Login form label input {
  width: inherit;
  border-radius: 12px;
  height: 20px;
  padding: 15px;
  outline: none;
  border: 1px solid #E7E6E6;
}
.Login form label .animatedPlaceHolder {
  position: absolute;
  top: 16px;
  left: 17px;
}
@keyframes placeholderMoveUp {
  100% {
    top: -10px;
    left: 15px;
    background-color: white;
    padding: 0px 2px 0px 2px;
  }
}
@keyframes placeholderMoveDown {
  0% {
    top: -10px;
    left: 15px;
    background-color: white;
    padding: 0px 2px 0px 2px;
  }
  100% {
    top: 16px;
    left: 15px;
    padding: 0px 2px 0px 2px;
  }
}
.Login form label .error {
  padding: 10px 0px 0px 10px;
  color: red;
  font-size: 14px;
}
.Login form .loginAsTitle {
  display: flex;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
  width: 100%;
}
.Login form .loginAsTitle .line {
  flex-grow: 5;
  height: 0px;
  border: 1px solid #E7E6E6;
}
.Login form .loginAsTitle span {
  flex-grow: 1;
  text-align: center;
}
.Login form .accountType {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  -moz-column-gap: 20px;
       column-gap: 20px;
  row-gap: 16px;
}
.Login form .accountType .type {
  text-align: center;
  flex-grow: 1;
  border-radius: 12px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.5s;
}
.Login form .accountType .guide {
  border: 1px solid #1967d2;
  color: #1967d2;
}
.Login form .accountType .guide:hover {
  background-color: #1967d2;
  color: white;
}
.Login form .accountType .tourist {
  border: 1px solid #d93025;
  color: #d93025;
}
.Login form .accountType .tourist:hover {
  background-color: #d93025;
  color: white;
}
.Login form .login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: "Inter";
  color: white;
  background-color: #eb662b;
  border: 1px solid transparent;
  outline: none;
  border-radius: 20px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.7s;
  height: 55px;
  font-weight: 600px;
}
.Login form .login:hover {
  background-color: #b93e09;
  color: white;
}
.Login form .login img {
  width: 50px;
}

.SuccessRegistered {
  font-family: "Inter";
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  margin: 150px auto 100px auto;
  box-shadow: 1px 1px 10px gray;
  padding: 40px 30px 40px 30px;
  border-radius: 10px;
  row-gap: 20px;
}
.SuccessRegistered .text {
  text-align: center;
}
.SuccessRegistered .link {
  text-decoration: none;
  padding: 10px 20px 10px 20px;
  background-color: #eb662b;
  color: white;
  border-radius: 10px;
  transition: all 0.5s;
}
.SuccessRegistered .link:hover {
  background-color: #cf4d14;
  box-shadow: 1px 1px 10px gray;
}

.GuidePostIcome {
  background-image: url("./img/passiveIncmeBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: -moz-fit-content;
  height: fit-content;
}
.GuidePostIcome .GuidePostIcome-leftSide {
  width: 50%;
  display: flex;
  justify-content: center;
}
.GuidePostIcome .GuidePostIcome-leftSide .GuidePostIcome-leftSide-alignement {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.GuidePostIcome .GuidePostIcome-leftSide .GuidePostIcome-leftSide-alignement h1 {
  max-width: 400px;
}
.GuidePostIcome .GuidePostIcome-leftSide .GuidePostIcome-leftSide-alignement h1 .keyWords {
  color: #5055f0;
}
.GuidePostIcome .GuidePostIcome-leftSide .GuidePostIcome-leftSide-alignement .link {
  text-decoration: none;
}
.GuidePostIcome .GuidePostIcome-leftSide .GuidePostIcome-leftSide-alignement button {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  font-family: "Inter";
  color: white;
  background-color: #5055f0;
  border: 1px solid transparent;
  outline: none;
  border-radius: 20px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.7s;
  padding-left: 20px;
}
.GuidePostIcome .GuidePostIcome-leftSide .GuidePostIcome-leftSide-alignement button:hover {
  background-color: #05073c;
  color: white;
}
.GuidePostIcome .GuidePostIcome-rightSide {
  max-width: 50%;
  position: relative;
}
.GuidePostIcome .GuidePostIcome-rightSide .sideWhiteWave {
  position: absolute;
  top: 0px;
}
.GuidePostIcome .GuidePostIcome-rightSide .bottomWhiteWave {
  position: absolute;
  top: 0px;
  width: 98vw;
}

@media (max-width: 900px) {
  .GeneralLayout .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  .GeneralLayout .header .header-left-side {
    position: relative;
  }
  .GeneralLayout .header .header-left-side nav {
    position: absolute;
    display: none;
    flex-wrap: wrap;
  }
  .GeneralLayout .header .header-left-side nav .link {
    text-decoration: none;
    font-size: 14px;
    width: -moz-fit-content;
    width: fit-content;
    font-family: "Inter";
    color: white;
    background-color: transparent;
    border: 1px solid transparent;
    outline: none;
    border-radius: 20px;
    padding: 12px;
    cursor: pointer;
    transition: all 0.7s;
  }
  .GeneralLayout .header .header-left-side nav .link:hover {
    background-color: rgba(239, 239, 239, 0.2);
    color: white;
  }
  .QuickSearch {
    padding: 100px 0px 0px 0px;
    row-gap: 50px;
  }
  .QuickSearch .QuickSearch-engine {
    padding: 20px 0px 0px 0px;
    border-radius: 10px;
    width: 80%;
  }
  .QuickSearch .QuickSearch-engine .searchOption {
    display: flex;
    -moz-column-gap: 8px;
         column-gap: 8px;
    cursor: pointer;
    position: relative;
    flex-grow: 1;
    flex-basis: 180px;
  }
  .QuickSearch .QuickSearch-engine .searchOption img {
    padding: 10px;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 50%;
    width: 20px;
    margin: 0px 0px 0px 20px;
  }
  .QuickSearch .QuickSearch-engine .searchOption .optionSelection {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .QuickSearch .QuickSearch-engine .searchOption .optionSelection .searchName {
    font-weight: 600;
  }
  .QuickSearch .QuickSearch-engine .searchOption .optionSelection .choosenOption {
    color: #737373;
    font-size: 12px;
  }
  .QuickSearch .QuickSearch-engine .searchOption .options {
    position: absolute;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 1px 10px gray;
    top: 60px;
    height: 250px;
    width: 335px;
    overflow-y: auto;
    z-index: 1;
    /* Custom scrollbar styles */
  }
  .QuickSearch .QuickSearch-engine .searchOption .options .option {
    padding: 16px 15px 16px 15px;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    transition: all 0.4s;
  }
  .QuickSearch .QuickSearch-engine .searchOption .options .option .type {
    justify-self: flex-end;
    color: gray;
    font-size: 11px;
  }
  .QuickSearch .QuickSearch-engine .searchOption .options .option:hover {
    background-color: rgb(222, 222, 222);
  }
  .QuickSearch .QuickSearch-engine .searchOption .options::-webkit-scrollbar-track {
    background-color: transparent;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .QuickSearch .QuickSearch-engine .searchOption .options::-webkit-scrollbar {
    width: 2px;
  }
  .QuickSearch .QuickSearch-engine .searchOption .options::-webkit-scrollbar-thumb {
    background-color: #888888;
  }
  .QuickSearch .QuickSearch-engine .searchOption .options::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
  }
  .QuickSearch .QuickSearch-engine button {
    background-color: #4a43c4;
    padding: 16px 20px 16px 20px;
    flex-grow: 1;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.8s;
    flex-basis: 170px;
  }
  .QuickSearch .QuickSearch-engine button:hover {
    background-color: #3933a1;
  }
  .QuickSearch .QuickSearch-slogan h1 {
    font-size: 50px;
  }
  .QuickSearch .QuickSearch-slogan span {
    display: block;
    width: 70%;
  }
  .SignUp form {
    padding: 20px 8px 10px 8px;
    width: 80%;
  }
  .SignUp form label {
    width: 90%;
  }
  .SignUp form label input {
    width: inherit;
    border-radius: 12px;
    height: 20px;
    padding: 15px;
    outline: none;
    border: 1px solid #E7E6E6;
  }
  .Login form {
    padding: 20px 8px 10px 8px;
    width: 80%;
  }
  .Login form label {
    width: 90%;
  }
  .Login form label input {
    width: inherit;
    border-radius: 12px;
    height: 20px;
    padding: 15px;
    outline: none;
    border: 1px solid #E7E6E6;
  }
  .GuidePostIcome .GuidePostIcome-leftSide {
    flex-grow: 1;
    width: -moz-fit-content;
    width: fit-content;
  }
  .GuidePostIcome .GuidePostIcome-rightSide {
    flex-grow: 1;
    position: relative;
  }
  .GuidePostIcome .GuidePostIcome-rightSide .mainImage {
    max-width: 100vw;
  }
  .GuidePostIcome .GuidePostIcome-rightSide .sideWhiteWave {
    position: absolute;
  }
}/*# sourceMappingURL=App.css.map */